<template>
  <div class="privacypolicy">
    <h1>Privacy Policy</h1>
    <h2>1. Acknowledgment and Acceptance of Terms</h2>
    <p>
      PocketLang is committed to protecting your privacy. This Privacy Statement sets forth our current privacy practices with regard to the information we collect when you or your computer interact with PocketLang.com. By accessing PocketLang.com, you acknowledge and fully understand our Privacy Statement and freely consent to the information collection and use practices described in this Website Privacy Statement.
    </p>

    <h2>2. Participating Clients, Merchant Policies, and Third Party Websites</h2>
    <p>
      Related services and offerings with links from this website, including all other websites, have their own privacy statements that can be viewed by clicking on the corresponding links within each respective website. All PocketLang advertising clients are encouraged to participate in industry privacy initiatives and to take a responsible attitude towards consumer privacy. PocketLang is not responsible for the privacy practices or contents of third-party or client websites. We recommend and encourage that you always review the privacy policies of merchants and other third parties before you provide any personal information or complete any transaction with such parties.
    </p>

    <h2>3. Information We Collect and How We Use It</h2>
    <p>
      PocketLang reserves the right to use contact information collected through advertising campaigns and other methods for commercial marketing purposes.
    </p>
    <h3>1a. Other Advertising (Non-Exhaustive)</h3>
    <p>
      We use technologies, such as cookies, to customize content and advertising, to provide social media features and to analyze traffic to the site. We also share information about your use of our site with our trusted social media, advertising and analytics partners, which are used for Facebook advertising purposes.
    </p>
    <p>
      You can also visit Facebook’s advertising opt-out page <a href="https://www.facebook.com/ads/preferences" target="_blank">here</a>.
    </p>

    <h2>4. Cookie Policy</h2>
    <p>
      Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.
    </p>
    <p>
      Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
    </p>
    <p>
      You may also opt out of the DoubleClick cookie by visiting the <a href="https://policies.google.com/technologies/ads" target="_blank">Google advertising opt-out page</a>.
    </p>
    <p>
      Or you may opt out of Google Analytics by visiting the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics Opt-out page</a>.
    </p>

    <h2>5. Changes to This Statement</h2>
    <p>
      PocketLang has the discretion to occasionally update this privacy statement. We encourage you to periodically review this privacy statement to stay informed about how we are helping to protect the personal information we collect.
    </p>

    <h2>6. Contacting Us</h2>
    <p>
      If you have questions regarding our Privacy Statement, its implementation, failure to adhere to this Privacy Statement and/or our general practices, please contact us.
    </p>

    <h2>7. Google Disclosures</h2>
    <p>
      Read Google’s advertiser guide to working with third parties <a href="https://support.google.com/adspolicy/answer/9457109?hl=en" target="_blank">here</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'privacypolicy',
  mounted() {
    document.title = "Privacy Policy"
  },
}
</script>

<style scoped lang="scss">
.privacypolicy {
  margin: 0 .5rem;
}
</style>